const deckJSON = (deck, game, folder) => {
    const url = `https://tabletop-simulator-mods.s3.amazonaws.com/${game}`;

    return {
        FaceURL: `${url}/${folder}/${deck.aws.toLowerCase()}`,
        BackURL: `${url}/back.jpg`,
        NumWidth: 5,
        NumHeight: 4,
        BackIsHidden: false,
        UniqueBack: false
    };
};

const cardJSON = (card, deckJSON) => ({
    Name: 'Card',
    Transform: {
        scaleX: 1.5,
        scaleY: 1.0,
        scaleZ: 1.5
    },
    Nickname: card.name,
    Description: '',
    ColorDiffuse: {
        r: 0.713235259,
        g: 0.713235259,
        b: 0.713235259
    },
    Locked: false,
    Grid: true,
    Snap: true,
    Autoraise: true,
    Sticky: true,
    Tooltip: true,
    GridProjection: false,
    Hands: true,
    CardID: parseInt(card.deck.id + card.id, 10),
    SidewaysCard: false,
    CustomDeck: { [card.deck.id]: deckJSON },
    XmlUI: '',
    LuaScript: '',
    LuaScriptState: ''
});

const buildCards = (object, game, folder) => {
    const containedObjects = [];
    const deckIDs = [];
    const customDeck = {};

    object.cards.forEach((card) => {
        const deckJson = deckJSON(card.deck, game, folder);
        if (!customDeck[card.deck.id]) customDeck[card.deck.id] = deckJson;

        const valor = card.deck.id + card.id;
        deckIDs.push(parseInt(valor, 10));

        containedObjects.push(cardJSON(card, deckJson, folder));
    });

    if (object.extra) {
        object.extra.forEach((hero) => {
            const deckJson = deckJSON(hero.deck, game, folder);
            if (!customDeck[hero.deck.id]) customDeck[hero.deck.id] = deckJson;

            const valor = hero.deck.id + hero.id;
            deckIDs.push(parseInt(valor, 10));

            containedObjects.push(cardJSON(hero, deckJson, folder));
        });
    }

    object.heroes.forEach((hero) => {
        const deckJson = deckJSON(hero.deck, game, folder);
        if (!customDeck[hero.deck.id]) customDeck[hero.deck.id] = deckJson;

        const valor = hero.deck.id + hero.id;
        deckIDs.push(parseInt(valor, 10));

        containedObjects.push(cardJSON(hero, deckJson, folder));
    });
    return { containedObjects, deckIDs, customDeck };
};

const objectStates = (object, game, folder) => {
    const content = buildCards(object, game, folder);

    const json = [];
    json.push({
        Name: 'Deck',
        Transform: {
            scaleX: 1.5,
            scaleY: 1.0,
            scaleZ: 1.5,
            rotX: 0,
            rotY: 180,
            rotZ: 0
        },
        Nickname: object.name,
        Description: '',
        ColorDiffuse: {
            r: 0.713235259,
            g: 0.713235259,
            b: 0.713235259
        },
        Locked: false,
        Grid: true,
        Snap: true,
        Autoraise: true,
        Sticky: true,
        Tooltip: true,
        GridProjection: false,
        Hands: false,
        SidewaysCard: false,
        DeckIDs: content.deckIDs,
        CustomDeck: content.customDeck,
        XmlUI: '',
        LuaScript: '',
        LuaScriptState: '',
        ContainedObjects: content.containedObjects
    });
    return json;
};

const buildJSON = (object, game, style) => {
    let folder = 'cards';
    if (style === 'legendcup') folder = 'legendcup';

    return {
        SaveName: '',
        GameMode: '',
        Gravity: 0.5,
        Date: '',
        Table: '',
        Sky: '',
        Note: '',
        Rules: '',
        XmlUI: '',
        LuaScript: '',
        ObjectStates: objectStates(object, game, folder),
        LuaScriptState: ''
    };
};

export default buildJSON;
